import React from "react"
import VideoPage from "../../components/VideoPage"

const data = [
  {
    name: "TRP 43 Transcending Lifetimes",
    link: "https://www.youtube.com/embed/DBRLL7X6jJQ",
  },
  {
    name: "TRP Chorale 43",
    link: "https://www.youtube.com/embed/atpeXPnklc0",
  },
  {
    name: "TRP 44",
    link: "https://www.youtube.com/embed/4J1Rll303b4",
  },
  {
    name: "TRP Chorale 44",
    link: "https://www.youtube.com/embed/mocD5owBHUM",
  },
  {
    name: "TRP 45",
    link: "https://www.youtube.com/embed/rkxX31IqteA",
  },
  {
    name: "TRP Chorale 45",
    link: "https://www.youtube.com/embed/g8GVbXUm6dQ",
  },
]

const TaoRinPalaPage = () => <VideoPage videos={data} title="Tao Rin Pala" />

export default TaoRinPalaPage
